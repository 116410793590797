import React from 'react'

const SignaxWhite: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="810"
      height="957"
      viewBox="0 0 810 957"
      fill="none"
      color="white"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M204.599 29.2247C203.267 39.179 202.696 49.5163 202.696 60.0449C202.696 169.543 276.923 245.54 424.424 288.037L465.725 299.14C483.806 304.117 496.558 307.18 503.98 310.243C511.403 312.349 520.919 315.22 530.435 319.432C550.61 327.472 555.939 338.766 555.939 351.783C555.939 376.095 531.577 388.346 483.806 388.346C419.095 388.346 374.559 357.909 350.198 298.183L168.818 398.492C205.931 507.99 317.272 576.904 478.477 576.904C505.693 576.904 531.197 574.99 555.178 571.161L0 738.662L204.599 29.2247Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M572.874 98.5233C552.7 48.943 518.822 23.4829 471.051 23.4829C433.937 23.4829 414.905 39.7545 414.905 61.0031C414.905 76.1261 423.469 87.4204 439.266 95.4604C456.205 103.5 487.989 113.646 535.761 126.855C565.261 135.278 578.584 138.723 599.9 146.955L749.305 0.702835H748.163L572.874 98.5233Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.38 806.664C74.6091 804.348 83.9351 807.733 92.119 812.365V777.092C80.3189 774.064 70.422 773.886 56.7186 777.271C22.4601 786 4.18896 812.721 4.18896 838.017C4.18896 861.354 18.6536 871.152 41.3023 881.484C47.9637 884.691 59.1929 889.144 62.809 895.379C64.3316 898.23 64.9026 900.902 64.9026 904.108C64.9026 916.222 55.0057 924.06 41.3023 924.595C30.0731 925.129 20.7472 921.566 11.6117 917.113V947.753C21.8892 953.276 34.07 956.126 46.4411 956.126C82.9835 956.126 103.919 930.83 103.919 898.942C103.919 847.281 43.3959 856.544 43.3959 827.151C43.3959 817.531 51.5798 809.158 63.38 806.664Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M130.18 956.108H170.148V743.044L130.18 755.086V956.108Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M336.115 811.9C336.495 818.22 336.495 824.725 336.495 831.231C336.495 849.075 335.924 867.291 332.879 884.949C326.218 923.983 306.043 956.697 271.785 956.697C217.352 956.697 200.033 893.314 200.033 844.986C200.033 794.985 215.068 728.069 271.785 713.013C302.618 704.835 322.602 723.422 330.976 756.88L292.34 782.903C290.437 769.148 287.201 750.746 272.166 754.092C244.949 760.04 242.856 817.104 242.856 838.666C242.856 861.343 243.807 915.433 272.546 914.132C294.243 913.202 296.718 880.302 297.098 860.599L271.785 863.202V821.379L336.115 811.9Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M453.549 956.678L403.872 788.491C405.394 798.668 405.965 809.421 405.965 819.982V956.7H365.997V684.602L403.381 673.343L453.547 840.72C451.643 827.855 450.501 814.798 450.501 801.741V659.137L491.041 646.927V956.678H453.549Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M605.588 956.657L598.001 900.99L557.842 902.905L550.595 956.696H510.642L559.11 626.276L596.196 615.244L647.872 956.657H605.588ZM591.91 850.836C587.723 819.824 583.536 789.578 580.491 758.375C579.349 744.401 578.017 730.044 577.255 716.261C575.352 762.204 569.642 807.956 563.933 853.324L591.91 850.836Z"
        fill="currentColor"
      />
      <path
        d="M809.324 956.693L684.794 588.563L646.552 600.056L770.67 956.693"
        fill="currentColor"
      />
      <path
        d="M704.973 956.694L802.983 552.964L756.005 567.087L666.755 956.694"
        fill="currentColor"
      />
    </svg>
  )
}

export default SignaxWhite
