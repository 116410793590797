import React from 'react'

const SignalWhite: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="749"
      height="956"
      viewBox="0 0 749 956"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M204.823 29.0239C203.493 38.9622 202.923 49.2826 202.923 59.7942C202.923 169.115 277.03 244.989 424.293 287.418L465.527 298.503C483.578 303.472 496.309 306.53 503.72 309.588C511.131 311.69 520.632 314.557 530.132 318.761C550.274 326.789 555.595 338.065 555.595 351.061C555.595 375.333 531.272 387.565 483.578 387.565C418.972 387.565 374.509 357.177 350.186 297.547L169.1 397.694C206.153 507.014 317.313 575.818 478.258 575.818C505.43 575.818 530.892 573.906 554.835 570.084L0.555176 737.314L204.823 29.0239Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M572.504 98.2091C552.362 48.709 518.539 23.2901 470.845 23.2901C433.792 23.2901 414.79 39.5353 414.79 60.7496C414.79 75.8481 423.341 87.1242 439.112 95.1512C456.024 103.178 487.756 113.308 535.451 126.495C564.903 134.904 578.204 138.344 599.486 146.562L748.649 0.546875H747.509L572.504 98.2091Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.8296 805.206C75.0406 802.894 84.3515 806.273 92.5222 810.897V775.682C80.7411 772.659 70.8603 772.481 57.179 775.86C22.976 784.575 4.73438 811.253 4.73438 836.509C4.73438 859.808 19.1756 869.59 41.7876 879.905C48.4382 883.107 59.6493 887.553 63.2596 893.778C64.7797 896.624 65.3498 899.291 65.3498 902.493C65.3498 914.587 55.4689 922.412 41.7876 922.946C30.5766 923.48 21.2659 919.923 12.1451 915.476V946.067C22.406 951.581 34.567 954.426 46.9181 954.426C83.4014 954.426 104.303 929.171 104.303 897.335C104.303 845.757 43.8778 855.006 43.8778 825.659C43.8778 816.055 52.0486 807.696 63.8296 805.206Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M130.525 954.404H170.428V741.684L130.525 753.707V954.404Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M336.127 810.434C336.507 816.743 336.507 823.238 336.507 829.734C336.507 847.549 335.937 865.735 332.896 883.365C326.246 922.336 306.104 954.997 271.901 954.997C217.556 954.997 200.265 891.716 200.265 843.466C200.265 793.546 215.276 726.739 271.901 711.707C302.684 703.542 322.635 722.1 330.996 755.503L292.423 781.484C290.523 767.751 287.292 749.379 272.281 752.72C245.109 758.658 243.018 815.63 243.018 837.157C243.018 859.797 243.968 913.799 272.661 912.5C294.323 911.573 296.793 878.726 297.173 859.055L271.901 861.653V819.898L336.127 810.434Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M453.37 954.98L403.773 787.065C405.294 797.225 405.864 807.961 405.864 818.505V955.001H365.96V683.343L403.284 672.103L453.368 839.209C451.468 826.365 450.328 813.329 450.328 800.292V657.919L490.801 645.729V954.98H453.37Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M605.168 954.952L597.593 899.375L557.499 901.286L550.264 954.991H510.375L558.765 625.106L595.791 614.091L647.383 954.952H605.168ZM591.512 849.302C587.332 818.34 583.151 788.143 580.111 756.991C578.971 743.039 577.641 728.705 576.881 714.945C574.981 760.813 569.28 806.491 563.58 851.786L591.512 849.302Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M666.952 592.615V954.992H748.469V885.615L706.665 887.909V580.713L666.952 592.615Z"
        fill="white"
      />
    </svg>
  )
}

export default SignalWhite
