import React from 'react'

const SignalButton: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="811"
      height="959"
      viewBox="0 0 811 959"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M731.116 959H79.1364C35.5253 959 0.111816 924.112 0.111816 881.147V77.8528C0.111816 34.8884 35.5253 0 79.1364 0H731.116C774.728 0 810.141 34.8884 810.141 77.8528V881.147C810.141 924.112 774.728 959 731.116 959Z"
        fill="url(#paint0_linear_2202_5968)"
      />
      <path
        d="M310.539 228.55C309.798 234.087 309.48 239.837 309.48 245.693C309.48 306.597 350.766 348.868 432.809 372.506L455.781 378.681C465.838 381.45 472.931 383.153 477.059 384.857C481.188 386.028 486.481 387.625 491.774 389.968C502.995 394.44 505.96 400.722 505.96 407.962C505.96 421.485 492.409 428.299 465.838 428.299C429.845 428.299 405.073 411.37 391.523 378.149L290.637 433.943C311.28 494.847 373.209 533.178 462.874 533.178C478.012 533.178 492.198 532.113 505.536 529.984L196.738 623.15L310.539 228.55Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M515.379 267.096C504.158 239.519 485.315 225.358 458.743 225.358C438.1 225.358 427.514 234.408 427.514 246.227C427.514 254.639 432.278 260.921 441.064 265.393C450.486 269.865 468.165 275.508 494.736 282.855C511.145 287.54 518.555 289.456 530.411 294.035L613.513 212.687H612.878L515.379 267.096Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M231.989 660.976C238.235 659.688 243.422 661.57 247.974 664.146V644.527C241.411 642.843 235.906 642.744 228.284 644.626C209.229 649.482 199.066 664.345 199.066 678.415C199.066 691.395 207.111 696.845 219.709 702.592C223.414 704.375 229.66 706.852 231.671 710.32C232.518 711.906 232.836 713.392 232.836 715.176C232.836 721.913 227.331 726.273 219.709 726.57C213.463 726.868 208.276 724.886 203.195 722.409V739.452C208.911 742.523 215.686 744.109 222.567 744.109C242.893 744.109 254.537 730.039 254.537 712.302C254.537 683.567 220.873 688.72 220.873 672.371C220.873 667.02 225.425 662.363 231.989 660.976Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M269.146 744.097H291.376V625.587L269.146 632.286V744.097Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M383.69 663.888C383.902 667.403 383.902 671.022 383.902 674.64C383.902 684.565 383.584 694.697 381.89 704.519C378.185 726.23 366.964 744.427 347.909 744.427C317.632 744.427 307.999 709.172 307.999 682.291C307.999 654.48 316.362 617.26 347.909 608.886C365.058 604.337 376.174 614.675 380.832 633.285L359.342 647.759C358.283 640.109 356.484 629.873 348.121 631.734C332.982 635.043 331.818 666.783 331.818 678.776C331.818 691.389 332.347 721.475 348.332 720.751C360.4 720.234 361.777 701.934 361.988 690.975L347.909 692.423V669.161L383.69 663.888Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M449.007 744.415L421.376 650.867C422.223 656.528 422.541 662.509 422.541 668.383V744.427H400.31V593.082L421.103 586.82L449.006 679.918C447.947 672.762 447.312 665.499 447.312 658.237V578.918L469.861 572.127V744.415H449.007Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M533.577 744.4L529.357 713.437L507.02 714.502L502.989 744.422H480.767L507.726 560.637L528.353 554.5L557.096 744.4H533.577ZM525.97 685.54C523.641 668.291 521.312 651.468 519.618 634.112C518.983 626.34 518.242 618.354 517.818 610.688C516.76 636.242 513.584 661.69 510.408 686.925L525.97 685.54Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M568.001 542.539V744.425H613.415V705.774L590.126 707.052V535.908L568.001 542.539Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2202_5968"
          x1="827.819"
          y1="63.0381"
          x2="-4.97679"
          y2="908.368"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2C4D66" />
          <stop offset="1" stopColor="#102538" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default SignalButton
