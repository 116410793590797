import React from 'react'
import Typography from '../Common/Typography'
import { useTranslations } from '../../hooks/use-translations'

import * as s from './ColorPalette.module.scss'

interface IColorPalette {
  title: string
  color: string
  size: number
  borderColor?: string
}

const colorPalette: Record<number, IColorPalette[]> = {
  0: [
    {
      title: 'Gradient\n #2D4E67 – #112538',
      color: 'linear-gradient(248.59deg, #2D4E67 2%, #112538 98%)',
      size: 60,
    },
  ],
  1: [
    {
      title: 'Dark blue\n #102538',
      color: '#102538',
      size: 30,
    },
    {
      title: 'Blue\n #0695D7',
      color: '#0695D7',
      size: 30,
    },
    {
      title: 'White\n #FFFFFF',
      color: '#FFFFFF',
      borderColor: '#ECF0F3',
      size: 30,
    },
    {
      title: 'Primary gray\n #ECF0F3',
      color: '#ECF0F3',
      size: 30,
    },
  ],
  2: [
    {
      title: 'Black\n #000000',
      color: '#000000',
      size: 30,
    },
    {
      title: 'Gray\n #5B5B5B',
      color: '#5B5B5B',
      size: 30,
    },
    {
      title: 'Light gray\n #DCDCDC',
      color: '#DCDCDC',
      size: 30,
    },
    {
      title: 'Medium gray\n #B6BEC7',
      color: '#B6BEC7',
      size: 30,
    },
  ],
  3: [
    {
      title: 'Deep gray\n #8F98A1',
      color: '#8F98A1',
      size: 30,
    },
    {
      title: 'Deep blue\n #0675D7',
      color: '#0675D7',
      size: 30,
    },
    {
      title: 'Light blue\n #8EDDFF',
      color: '#8EDDFF',
      size: 30,
    },
    {
      title: 'Destructive red\n #FF4758',
      color: '#FF4758',
      size: 30,
    },
  ],
  4: [
    {
      title: 'Violet\n #8D90FF',
      color: '#8D90FF',
      size: 30,
    },
    {
      title: 'Light violet\n #BFC1FF',
      color: '#BFC1FF',
      size: 30,
    },
    {
      title: 'Yellow\n #F2C94C',
      color: '#F2C94C',
      size: 30,
    },
    {
      title: 'Positive green\n #4FCDA6',
      color: '#4FCDA6',
      size: 30,
    },
  ],
}

const ColorPalette = () => {
  const { t } = useTranslations()

  return (
    <>
      <div style={{ marginBottom: 24 }}>
        {renderColors(
          colorPalette[0].map(item => ({
            ...item,
            title: `${t('Signal')} ${item.title}`,
          }))
        )}
      </div>
      <div className="flex flex-col" style={{ gap: 16 }}>
        {renderColors(colorPalette[1])}
        {renderColors(colorPalette[2])}
        {renderColors(colorPalette[3])}
        {renderColors(colorPalette[4])}
      </div>
    </>
  )
}

export default ColorPalette

function renderColors(color: IColorPalette[]) {
  return (
    <div className={s.colors}>
      {color.map((item, idx) => (
        <div className={s.color} key={item.title + idx}>
          <div
            style={{
              width: item.size,
              height: item.size,
              borderRadius: '50%',
              border: item.borderColor
                ? `1px solid ${item.borderColor}`
                : undefined,
              background: item.color,
              marginBottom: 8,
            }}
          />
          <Typography variant="body2" size={18}>
            {item.title}
          </Typography>
        </div>
      ))}
    </div>
  )
}
