import React from 'react'

const SignalDark: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="749"
      height="956"
      viewBox="0 0 749 956"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M204.268 29.0239C202.938 38.9622 202.368 49.2826 202.368 59.7942C202.368 169.115 276.475 244.989 423.738 287.418L464.971 298.503C483.023 303.472 495.754 306.53 503.165 309.588C510.576 311.69 520.076 314.557 529.577 318.761C549.719 326.789 555.039 338.065 555.039 351.061C555.039 375.333 530.717 387.565 483.023 387.565C418.417 387.565 373.953 357.177 349.631 297.547L168.545 397.694C205.598 507.014 316.758 575.818 477.703 575.818C504.875 575.818 530.337 573.906 554.279 570.084L0 737.314L204.268 29.0239Z"
        fill="#102538"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M571.947 98.2091C551.805 48.709 517.983 23.2901 470.288 23.2901C433.235 23.2901 414.233 39.5353 414.233 60.7496C414.233 75.8481 422.784 87.1242 438.556 95.1512C455.467 103.178 487.2 113.308 534.894 126.495C564.347 134.904 577.648 138.344 598.93 146.562L748.093 0.546875H746.953L571.947 98.2091Z"
        fill="#102538"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.2744 805.206C74.4854 802.894 83.7963 806.273 91.967 810.897V775.682C80.186 772.659 70.3051 772.481 56.6239 775.86C22.4208 784.575 4.1792 811.253 4.1792 836.509C4.1792 859.808 18.6205 869.59 41.2325 879.905C47.8831 883.107 59.0941 887.553 62.7044 893.778C64.2246 896.624 64.7946 899.291 64.7946 902.493C64.7946 914.587 54.9137 922.412 41.2325 922.946C30.0215 923.48 20.7107 919.923 11.5899 915.476V946.067C21.8508 951.581 34.0119 954.426 46.363 954.426C82.8462 954.426 103.748 929.171 103.748 897.335C103.748 845.757 43.3227 855.006 43.3227 825.659C43.3227 816.055 51.4934 807.696 63.2744 805.206Z"
        fill="#102538"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M129.97 954.404H169.873V741.684L129.97 753.707V954.404Z"
        fill="#102538"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M335.569 810.434C335.949 816.743 335.949 823.238 335.949 829.734C335.949 847.549 335.379 865.735 332.339 883.365C325.689 922.336 305.547 954.997 271.344 954.997C216.999 954.997 199.708 891.716 199.708 843.466C199.708 793.546 214.719 726.739 271.344 711.707C302.126 703.542 322.078 722.1 330.439 755.503L291.866 781.484C289.965 767.751 286.735 749.379 271.724 752.72C244.551 758.658 242.461 815.63 242.461 837.157C242.461 859.797 243.411 913.799 272.104 912.5C293.766 911.573 296.236 878.726 296.616 859.055L271.344 861.653V819.898L335.569 810.434Z"
        fill="#102538"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M452.814 954.98L403.217 787.065C404.737 797.225 405.307 807.961 405.307 818.505V955.001H365.404V683.343L402.728 672.103L452.812 839.209C450.912 826.365 449.772 813.329 449.772 800.292V657.919L490.245 645.729V954.98H452.814Z"
        fill="#102538"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M604.613 954.952L597.038 899.375L556.944 901.286L549.709 954.991H509.82L558.21 625.106L595.235 614.091L646.828 954.952H604.613ZM590.957 849.302C586.777 818.34 582.596 788.143 579.556 756.991C578.416 743.039 577.086 728.705 576.326 714.945C574.426 760.813 568.725 806.491 563.025 851.786L590.957 849.302Z"
        fill="#102538"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M666.396 592.615V954.992H747.914V885.615L706.11 887.909V580.713L666.396 592.615Z"
        fill="#102538"
      />
    </svg>
  )
}

export default SignalDark
