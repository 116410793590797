import React from 'react'

const SignaxButton: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return (
    <svg
      width="812"
      height="957"
      viewBox="0 0 812 957"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M732.193 956.701H79.1561C35.4743 956.701 0.00341797 921.922 0.00341797 879.092V78.3107C0.00341797 35.4807 35.4743 0.701431 79.1561 0.701431H732.193C775.875 0.701431 811.346 35.4807 811.346 78.3107V879.092C811.346 921.922 775.875 956.701 732.193 956.701Z"
        fill="url(#paint0_linear_12680_8217)"
      />
      <path
        d="M292.408 229.916C291.667 235.454 291.349 241.204 291.349 247.061C291.349 307.974 332.641 350.25 414.696 373.891L437.671 380.068C447.729 382.837 454.823 384.54 458.952 386.244C463.082 387.416 468.375 389.013 473.669 391.356C484.892 395.828 487.857 402.111 487.857 409.353C487.857 422.877 474.304 429.692 447.729 429.692C411.731 429.692 386.956 412.76 373.404 379.535L272.503 435.336C293.149 496.249 355.087 534.586 444.765 534.586C459.905 534.586 474.093 533.521 487.433 531.391L178.59 624.57L292.408 229.916Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M497.273 268.464C486.05 240.883 467.204 226.72 440.629 226.72C419.983 226.72 409.396 235.771 409.396 247.592C409.396 256.005 414.16 262.288 422.948 266.76C432.371 271.233 450.052 276.877 476.627 284.225C493.038 288.91 500.449 290.827 512.308 295.406L595.421 214.047H594.786L497.273 268.464Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M213.852 662.397C220.099 661.109 225.287 662.992 229.839 665.568V645.947C223.275 644.262 217.769 644.163 210.146 646.046C191.088 650.902 180.924 665.767 180.924 679.839C180.924 692.821 188.971 698.271 201.57 704.019C205.276 705.803 211.523 708.28 213.534 711.749C214.381 713.334 214.699 714.821 214.699 716.605C214.699 723.343 209.193 727.704 201.57 728.001C195.324 728.298 190.136 726.316 185.054 723.839V740.884C190.771 743.956 197.547 745.542 204.429 745.542C224.757 745.542 236.404 731.469 236.404 713.731C236.404 684.992 202.735 690.145 202.735 673.794C202.735 668.442 207.288 663.785 213.852 662.397Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M251.009 745.532H273.243V627.005L251.009 633.705V745.532Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M365.576 665.311C365.788 668.827 365.788 672.446 365.788 676.065C365.788 685.992 365.471 696.125 363.776 705.948C360.071 727.662 348.848 745.861 329.79 745.861C299.509 745.861 289.875 710.601 289.875 683.717C289.875 655.902 298.239 618.677 329.79 610.302C346.942 605.752 358.059 616.092 362.718 634.704L341.225 649.181C340.166 641.529 338.366 631.292 330.002 633.153C314.861 636.462 313.697 668.206 313.697 680.201C313.697 692.816 314.226 722.906 330.214 722.182C342.283 721.665 343.66 703.363 343.872 692.402L329.79 693.85V670.585L365.576 665.311Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M430.897 745.85L403.261 652.289C404.108 657.95 404.426 663.932 404.426 669.807V745.862H382.192V594.496L402.989 588.233L430.895 681.343C429.837 674.186 429.201 666.923 429.201 659.659V580.33L451.753 573.538V745.85H430.897Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M515.485 745.835L511.264 714.868L488.924 715.933L484.892 745.857H462.667L489.629 562.047L510.26 555.91L539.007 745.835H515.485ZM507.876 686.968C505.547 669.716 503.217 652.891 501.523 635.533C500.888 627.759 500.147 619.772 499.723 612.105C498.665 637.663 495.488 663.114 492.312 688.352L507.876 686.968Z"
        fill="white"
      />
      <path
        d="M628.803 745.862L559.528 541.075L538.254 547.468L607.3 745.862"
        fill="white"
      />
      <path
        d="M570.744 745.862L625.266 521.27L599.132 529.127L549.483 745.862"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_12680_8217"
          x1="829.052"
          y1="63.5423"
          x2="-1.06999"
          y2="910.173"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2C4D66" />
          <stop offset="1" stopColor="#102538" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default SignaxButton
