import React from 'react'
import cn from 'classnames'

import { AppRegionEnum } from '../../types/app-region.enum'
import SignalDark from '../../svg/signal-dark'
import SignalWhite from '../../svg/signal-white'
import SignalButton from '../../svg/signal-button'
import SignaxDark from '../../svg/signax-dark'
import SignaxWhite from '../../svg/signax-white'
import SignaxButton from '../../svg/signax-button'

import * as s from './Logotypes.module.scss'

interface LogotypesProps {
  region: AppRegionEnum
}

const Logotypes = ({ region }: LogotypesProps) => {
  switch (region) {
    case AppRegionEnum.Ru:
      return (
        <>
          <SignalDark className={s.logo} />
          <div className={cn('relative', s.logo)}>
            <div className={s.logo_shadow} />
            <SignalWhite className="absolute w-100 h-100" />
          </div>
          <SignalButton className={s.logo} />
        </>
      )

    case AppRegionEnum.Us:
      return (
        <>
          <SignaxDark className={s.logo} />
          <div className={cn('relative', s.logo)}>
            <div className={s.logo_shadow} />
            <SignaxWhite
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
              }}
            />
          </div>
          <SignaxButton className={s.logo} />
        </>
      )
  }
}

export default Logotypes
